import React, { useState } from 'react';
import MainButton from './MainButton'; // Adjust the import path as necessary
import '../App.css'; // Import your CSS file

const TextInputComponent = ({onNextClick}) => {
    const [fileName, setFileName] = useState('');
    const [additionalText, setAdditionalText] = useState('');

    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
    };

    const handleAdditionalTextChange = (event) => {
        setAdditionalText(event.target.value);
    };

    const handleSaveClick = () => {
        // Handle save logic here
        console.log('File name saved:', fileName);
        console.log('Additional text saved:', additionalText);
    };

    console.log('CreateTemplatePage - onNextClick:', onNextClick);


    return (
        <div className="upload-container">
            <div className="input-container">
                <p className='secondary-text'>Name:</p>
                <input 
                    type="text" 
                    value={fileName} 
                    onChange={handleFileNameChange} 
                    placeholder="Enter a name for your file" 
                    className="file-input"
                />
                <p className='secondary-text'>Description:</p>
                <input 
                    type="text" 
                    value={additionalText} 
                    onChange={handleAdditionalTextChange} 
                    placeholder="Optional" 
                    className="file-input"
                />
            </div>
            <div className="button-container">
                <MainButton 
                    onClick={onNextClick} 
                    text="Next" 
                    width="100px" 
                    backgroundColor="#1f477d" 
                    fontColor="#ffffff" 
                    className="main-button"
                />
            </div>
        </div>
    );
};

export default TextInputComponent;