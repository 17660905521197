import {useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback} from 'react';
import WebViewer from '@pdftron/webviewer'
import { saveFile } from '../utils/utils';
import save from '../icons/Save.svg';

const WebViewComponent = forwardRef(({ hasLink, initialFile, fileKey, id, onTextSelected, startPageNumber }, ref) => {
  const viewer = useRef(null);

  const [selectedText, setSelectedText] = useState('');
  const [pageNumber, setPageNumber] = useState(null);

  const [initPageNumber, setInitPageNumber] = useState(startPageNumber)

  const [instance, setInstance] = useState(null);
  const [isActionButtonAdded, setIsActionButtonAdded] = useState(false); // Step 1: New state variable 
  var buttonNeeded = true;


  // Make the method accessible from the parent component
  useImperativeHandle(ref, () => ({
    getText: () => {
      return { selectedText, pageNumber };
    }
  }));

  useEffect(() => {
    console.log('startPageNumber', startPageNumber)
    setInitPageNumber(startPageNumber)
  }, [])

  useEffect(() => {
    WebViewer(
      {
        path: '/public',
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        initialDoc: null,
        loadAsPDF: true,
        // enableOfficeEditing: true,
      },
      viewer.current,
    ).then((i) => {
      setInstance(i);
      const { documentViewer, Annotations, annotationManager, Tools } = i.Core;

      documentViewer.setSearchHighlightColors({
        searchResult: new Annotations.Color(0, 0, 255, 0.5),
        activeSearchResult: 'rgba(0, 255, 0, 0.5)'
      });

      const showSelectedText = () => {
        const page = documentViewer.getCurrentPage();
        console.log('page', page)
        const text = documentViewer.getSelectedText(page);
        if (text) {
          console.log('text', text);
        }
      }

      documentViewer.addEventListener('keyDown', function (e) {
        if (e.keyCode == 67 && (e.ctrlKey || e.metaKey)) {
          showSelectedText();
          console.log('Ctrl+C pressed');
        }
      });


      documentViewer.addEventListener('documentLoaded', () => {
        i.UI.setZoomLevel('100%');
        documentViewer.setCurrentPage(initPageNumber)

        
        console.log('doc Loaded HERE')

        



        const textSelectedListener = (quads, text, page) => {
          if (text.length > 0) {
            setSelectedText(text);
            setPageNumber(page);
            // console.log('page number', text, page);
          }
        };
    
        documentViewer.addEventListener('textSelected', textSelectedListener);

        

        const newLink = new Annotations.Link();
        newLink.PageNumber = 7;
        newLink.X = 100;
        newLink.Y = 95;
        newLink.Width = 200;
        newLink.Height = 100;
        newLink.StrokeStyle = 'underline';
        newLink.StrokeThickness = 2;

        const pageToLinkTo = 28;

        // newLink.addAction(
        //   'U',
        //   new Actions.GoTo({
        //     dest: new Actions.GoTo.Dest({
        //       page: pageToLinkTo,
        //     }),
        //   }),
        // );
        // console.log('hasLink', hasLink)

        if (hasLink) {
          annotationManager.addAnnotation(newLink);
          annotationManager.drawAnnotationsFromList(newLink.PageNumber);
        }

        // i.UI.setHeaderItems(header => {
        //   if (buttonNeeded) {
        //     console.log('pushing item!')
        //     header.push({
        //       type: 'actionButton',
        //       img: save,
        //       onClick: async () => {
        //         const doc = documentViewer.getDocument();
        //         console.log('doc', initialFile)
        //         const data = await doc.getFileData({});
        //         const arr = new Uint8Array(data);
        //         const blob = new Blob([arr]);
        //         await saveFile(id, blob);
        //       }
        //     });
        //     buttonNeeded = false;
        //   }
        // });

        return () => {
          documentViewer.removeEventListener('textSelected', textSelectedListener);
        };
      });
    });

    
  }, []);

  useEffect(() => {
    if (instance) {
      setIsActionButtonAdded(true);
      console.log('Loading new document:', initialFile);
      // instance.UI.loadDocument(initialFile, {extension: 'pdf'})
      instance.UI.loadDocument(initialFile)

    }
  }, [initialFile, instance, fileKey])

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
});

export default WebViewComponent;