import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useAuthInfo } from '@propelauth/react';
import MainButton from '../components/MainButton';
import { createNewDocumentSet, getDocumentSets, newDocumentSetName } from '../utils/utils';
import CustomStatusButton from '../components/CustomStatusButton';

function HomePage() {


  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [documentSets, setDocumentSets] = useState([]);

  const [newDocumentSetName, setNewDocumentSetName] = useState('')

  const [newDocumentModal, setNewDocumentModal] = useState(false)

  const handleNewDocumentModalOpen = () => setNewDocumentModal(true)
  const handleNewDocumentModalClose = () => setNewDocumentModal(false)

  const navigate = useNavigate();

  const outputFilesIdList = {
    'Nonclinical Overview': "12a1b1d4-9a95-4575-8d92-9eeacc7558d6",
    'Development Safety Update Report (DSUR)': "e01eef77-03b1-48ba-b149-01c9336c9330",
    'Clinical Study Report (CSR)': "ca0bffc5-5144-41a4-85b6-b656ddf870eb",
    'FDA Response': "f87840c7-be40-4458-a4d5-724486d3c167",
    'Protocol': "ab8a59fd-bd78-4b6b-9632-ede5c3829c64",
    'Summary of Product Characteristics (SmPC)': "0c9995b1-7573-4b79-a7ee-b2cb7bed4655",
    'FDA Cover Letter': "1f051784-065a-4baf-b72f-4476cbac23ed",
    'Patient Narrative': "d32aa512-ad65-42bf-a020-39ef5bf90d13",
    'Stability Summary': "c83d4ab4-cf99-49a3-93b5-3051152ce784"

  }

  const handleRowClick = (params) => {
    // navigate(`/documentSet/${params.row.doc_set}`, { state: { ...params.row } });
    navigate(`/document/${params.id}`);
    console.log('params', params)
  };

  useEffect(() => {
    async function retrieveData() {
      setLoading(true); // Start loading
      try {
        const result = await getDocumentSets(setLoading, setError); // Assuming getDocumentSets returns the data
        setDocumentSets(result); // Update state with fetched data
        console.log('result', result)
        setError(false); // Reset error state if successful
      } catch (error) {
        console.error(error);
        setError(true); // Set error state
      } finally {
        setLoading(false); // End loading regardless of outcome
      }
    }
    retrieveData();
  }, []);


  const navigateToDocumentWithUrl = () => {
    navigate(`/createDocument`);
  };


  for (let i = 0; i < documentSets.length; i++) {
    documentSets[i].id = i + 1;

    if (documentSets[i].doc_set === "Nonclinical Overview" || documentSets[i].doc_set === "CSR" || documentSets[i].doc_set === "Patient Narrative" || documentSets[i].doc_set === "DSUR" || documentSets[i].doc_set == "Stability Summary" || documentSets[i].doc_set == "Stability Data" || documentSets[i].doc_set == "FDA Cover Letter") {
      documentSets[i].status = "Ready for review"
    } else if (documentSets[i].doc_set === "Protocol" || documentSets[i].doc_set === "FDA Response" || documentSets[i].doc_set === "SmPC") {
      documentSets[i].status = "Draft generating..."
    } else {
      documentSets[i].status = "Draft not started"
    }

    // On backend, make sure id is stored with other document data 
    if (documentSets[i].doc_set === "Nonclinical Overview") {
      documentSets[i].id = "12a1b1d4-9a95-4575-8d92-9eeacc7558d6"
    } else if (documentSets[i].doc_set === "DSUR") {
      documentSets[i].id = "e01eef77-03b1-48ba-b149-01c9336c9330"
    } else if (documentSets[i].doc_set === "CSR") {
      documentSets[i].id = "ca0bffc5-5144-41a4-85b6-b656ddf870eb"
    } else if (documentSets[i].doc_set === "FDA Response") {
      documentSets[i].id = "f87840c7-be40-4458-a4d5-724486d3c167"
    } else if (documentSets[i].doc_set === "Protocol") {
      documentSets[i].id = "ab8a59fd-bd78-4b6b-9632-ede5c3829c64"
    } else if (documentSets[i].doc_set === "SmPC") {
      documentSets[i].id = "0c9995b1-7573-4b79-a7ee-b2cb7bed4655"
    } else if (documentSets[i].doc_set === "FDA Cover Letter") {
      documentSets[i].id = "1f051784-065a-4baf-b72f-4476cbac23ed"
    } else if (documentSets[i].doc_set === "Patient Narrative") {
      documentSets[i].id = "d32aa512-ad65-42bf-a020-39ef5bf90d13"
    } else if (documentSets[i].doc_set === "Stability Summary") {
      documentSets[i].id = "78cd98a6-f510-455c-9e3f-8f7ac6208a69"
    } else if (documentSets[i].doc_set === "Stability Data") {
      documentSets[i].id = "32dd5f7d-c0de-4be1-ae1f-ec454fc86b5a"
    }
  }

  // Filter out rows with doc_set equal to "Tester" or "CSR Modified"
  const filteredDocumentSets = documentSets.filter(
    (doc) => doc.doc_set !== "Tester " && doc.doc_set !== "CSR Modified"
  );



  const columns = [
    { field: 'doc_set', headerName: 'Document Set', flex: 1 },
    { field: 'owner', headerName: 'Owner', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomStatusButton isOn={params.row.status} />
    },
    // { field: 'docs_generated', headerName: 'Documents', flex: 1 },
    { field: 'last_updated', headerName: 'Last Updated', flex: 1 },
    { field: 'created', headerName: 'Created', flex: 1 },
  ];

  return (
    <div className="full-width-container"> {/* Add a class to the outermost div */}
      <div className="inner-container"> {/* Optional: Add a class for styling or leave as is */}
        <div className="header-container">
          <div className="main-panel-header">
            <h2 className="top-main-panel">Document Sets</h2>
            {/* <div className='new-document-button' onClick={() => handleNewDocumentModalOpen()}>
                  <p className='text-white'>New Document Set</p>
                  </div> */}
          </div>
          <div className='header-bar'>
            <div className='secondary-header-container'>
              <p className='secondary-text'>Manage and view all your submissions here</p>
            </div>
            <MainButton onClick={navigateToDocumentWithUrl} text='Create' width="15%" icon="fas fa-plus" className="main-button" />

          </div>

        </div>
        {documentSets.length >= 1 ? <div className='datagrid'>
          <DataGrid rows={filteredDocumentSets} columns={columns} onRowClick={(params) => handleRowClick({ id: params.row.id })} autoHeight />
        </div> : <div className='loading'>Loading...</div>}
      </div>
      <Modal
        open={newDocumentModal} onClose={handleNewDocumentModalClose}>
        <div className='modal-background'>
          <div className='modal-content'>
            <div className='form-container'>
              <h2>New Document Set</h2>
              <p>Document Set Name</p>
              <input className='modal-input' type='text'
                value={newDocumentSetName}
                onChange={(e) => setNewDocumentSetName(e.target.value)}
              ></input>
              <button className='modal-submit' onClick={async () => {
                console.log('newDocumentSetName', newDocumentSetName)
                await createNewDocumentSet(newDocumentSetName)
                handleNewDocumentModalClose()
                navigate(`/documentSet/${newDocumentSetName}`)
              }
              }>
                <p className='text-white'>Submit</p></button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={error} onClose={() => setError(false)}>
        <div className='modal-background' style={{ width: '40%', height: '40%' }}>
          <div className='modal-content'>
            Error. Please try again.
          </div>
        </div>
      </Modal>
    </div>

  );
}

export default HomePage;