import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import PDFWebViewComponent from '../components/PDFWebViewComponent';
import { uploadMultipleFilesUpdated, generateOutline, generateBoth, createINDModule2Section, extractAndStoreMultiple, createOutputVectorstore, processTemplate } from '../utils/utils';
import { getOneDocumentSet, listAllFiles } from '../utils/utils';
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import dots from '../icons/DotsVector.svg'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import MainButton from '../components/MainButton';
import TextInputComponent from '../components/TextInputComponent';
import MultipleSystemUploaderComponent from '../components/MultipleSystemUploaderComponent';

function CreateTemplatePage() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [templateFiles, setTemplateFiles] = useState([]);
    const [newTemplateName, setNewTemplateName] = useState('')
    const [newTemplateModal, setNewTemplateModal] = useState(false)
    const [viewTemplateModal, setViewTemplateModal] = useState(false)
    const [fileUrl, setFileUrl] = useState('')
    const [textInputView, setTextInputView] = useState(true)
    const [systemInputView, setSystemInputView] = useState(false)

    const [step, setStep] = useState(0)

    const documentTypeList = ['Nonclinical Overview', 'Document Type 1', 'Document Type 2', 'Document Type 3', 'Document Type 4', 'Document Type 5']
    const [documentType, setDocumentType] = useState('')


    const [multipleFileList, setMultipleFileList] = useState([]);
    const [multipleFileNameList, setMultipleFileNameList] = useState([]);
    const [multipleFileContentList, setMultipleFileContentList] = useState([]);

    const removeFile = (index) => {
        const newFileList = [...multipleFileList];
        newFileList.splice(index, 1);
        setMultipleFileList(newFileList); // Assuming your state setter is named like this
    };


    const handleContinue = () => {
        setStep((prevStep) => prevStep + 1); 
    };

    // const handleTemplateButtonClick = (url) => {
    //     setFileUrl(url)
    //     setViewTemplateModal(true)
    // }

    const navigate = useNavigate();

    const navigateToDocumentWithUrl = (params) => {
        navigate(`/editTemplate/${params.id}`, { state: { url: params.url } });
    };

    const textInputComplete = () => {
        console.log('This runs for setTextInputView!')
        setTextInputView(false)
        setSystemInputView(true)
    };

    console.log('CreateTemplatePage - textInputComplete:', textInputComplete);

    const systemInputComplete = () => {
        console.log('This runs for systemInputComplete!')
        setSystemInputView(false)
        setTextInputView(false)
        const params = { id: '12a1b1d4-9a95-4575-8d92-9eeacc7558d6' }
        navigateToDocumentWithUrl(params)
    };

    const backClick = () => {
        console.log('This runs for systemInputComplete!')
        setSystemInputView(false)
        setTextInputView(true)
    };

    console.log('CreateTemplatePage - textInputComplete:', textInputComplete);

    useEffect(() => {
        // async function retrieveInputs() {
        //     setLoading(true); // Start loading
        //     try {
        //         const result = await listAllFiles('templates'); // Assuming getDocumentSets returns the data
        //         console.log('inputs', result)
        //         setTemplateFiles(result); // Update state with fetched data
        //         setError(false); // Reset error state if successful
        //     } catch (error) {
        //         console.error(error);
        //         setError(true); // Set error state
        //     } finally {
        //         setLoading(false); // End loading regardless of outcome
        //     }
        // }
        // retrieveInputs();
        setTextInputView(true)
        setSystemInputView(false)
    }, []);

    const handleMultipleFiles = (files, fileContents) => {
        try {
            // Handle saving the file content or any other logic as needed for each file
            const newFiles = [];
            console.log('files here', files)

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const content = fileContents[i];
                newFiles.push({ name: file.name, content });
                console.log('newFiles', newFiles)
            }

            // Update React state with the array of file objects
            setMultipleFileList(newFiles);
            setMultipleFileNameList(newFiles.map((file) => file.name));
            setMultipleFileContentList(fileContents);
        } catch (e) {
            console.log('Error in handling files', e);
        }
    };

    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    const formattedDate = threeDaysAgo.toLocaleDateString();


    console.log('CreateTemplatePage - textInputComplete:', textInputComplete);


    return (
        <div className='full-width-container' style={{ backgroundColor: '#F9FAFB' }}>
            <div className='inner-container'>
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Create a new template</h2>
                    </div>
                    {/* <div className='header-bar'>
                        <div className='secondary-header-container'>
                            <p className='secondary-text'>Upload a template that works for you </p>
                        </div>
                    </div> */}
                    <div className='main-panel-content'>
                        {textInputView && <TextInputComponent onNextClick={textInputComplete}/>}
                        {systemInputView && <MultipleSystemUploaderComponent onNextClick1={systemInputComplete} onNextClick2={backClick}/>}
                    </div>
                </div>
            </div>
            <Modal open={newTemplateModal} onClose={() => setNewTemplateModal(false)}>
                <div className='modal-background'>
                    <Slide direction="up" in={step === 0} mountOnEnter unmountOnExit>
                        <div className='modal-content'>
                            <h4>Select Document Type</h4>
                            <div className='button-list'>
                                {documentTypeList.map((type) => (
                                    <button
                                        key={type}
                                        className={`document-type-button ${documentType === type ? 'selected' : ''}`}
                                        onClick={() => setDocumentType(type)}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                            {documentType &&
                                <div className='modal-button-container'>
                                    <button onClick={() => handleContinue()} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                                </div>}

                        </div>
                    </Slide>
                    <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                        <div className='modal-content'>
                            <h4>Create New Template</h4>
                            <div className="form-container">
                                <div className={`input-container ${multipleFileList.length < 1 ? 'dashed-border' : ''}`}>
                                    {multipleFileList.length >= 1 && <p style={{ textAlign: 'left' }}>Files Selected:</p>}
                                    {multipleFileList.length == 0 && <MultipleFileUploader handleFiles={handleMultipleFiles} allowMultiple={false} />}
                                    {multipleFileList.length >= 1 && (
                                        <>
                                            {multipleFileList.map((file, index) => (
                                                <div className='multiple-file-upload-card' key={file.name}>
                                                    <div className='file-info'>
                                                        <img src={paperclip} alt="Paperclip" />
                                                        <p className='file-name'>{file.name}</p>
                                                    </div>
                                                    <button className='remove-file-btn' onClick={() => removeFile(index)}>Remove</button>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='modal-buttons'>
                                <button className='select-file-btn' onClick={async () => {
                                    setNewTemplateModal(false)
                                    await uploadMultipleFilesUpdated(setLoading, multipleFileList, multipleFileContentList, setError, 'templates');
                                    await processTemplate(setLoading, setError, multipleFileNameList[0], documentType)
                                }}>Upload</button>
                            </div>
                        </div>

                    </Slide>
                </div>
            </Modal>
            <Modal open={viewTemplateModal} onClose={() => setViewTemplateModal(false)}>
                <div className='modal-background'>
                    <div className='modal-content'>
                        <h4>View Template</h4>
                        <div className='webviewContainer' style={fileUrl ? { height: '60vh', width: '75%', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                            <PDFWebViewComponent initialFile={fileUrl}></PDFWebViewComponent>
                        </div>
                        <div className='modal-buttons'>
                            <button className='select-file-btn' style={{ margin: '5px' }} onClick={() => setViewTemplateModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default CreateTemplatePage;