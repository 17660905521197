import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, Outlet, NavLink} from 'react-router-dom';
import './App.css';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import HomePage from './pages/HomePage';
import DocumentSetsPage from './pages/DocumentSetsPage';
import DocumentPage from './pages/DocumentPage';
import TemplatesPage from './pages/TemplatesPage';
import AutomationsPage from './pages/AutomationsPage';
import EditTemplatePage from './pages/EditTemplatePage';
import CreateTemplatePage from './pages/CreateTemplatePage';
import IndividualAutomationPage from './pages/IndividualAutomationPage';
import logo from './icons/LOGO.svg';
import folderOpen from './icons/Folder open.svg';
import archive from './icons/Archive.svg';
import support from './icons/Support.svg';
import cog from './icons/Cog.svg';
import flag from './icons/Flag.svg';
import { withAuthInfo } from '@propelauth/react'
import CreateDocumentPage from './pages/CreateDocumentPage';





// Define your page components here
const About = () => <div><h2>About</h2></div>;
const Dashboard = () => <div><h2>Dashboard</h2></div>;
const NoMatch = () => (
  <div>
    <div>No match page!</div>
    <h2>Nothing to see here!</h2>
    <p><Link to="/">Go to the home page</Link></p>
  </div>
);


const Settings = () => (

  <div className='full-width-container'><h2>View Settings Here</h2></div>

)

const Help = () => (

  <div className='full-width-container'><h2>For help, contact info@artosai.com</h2></div>

)




// Layout component for content
function Layout() {
  return (
    <div className='main-panel'>
      <Outlet /> {/* Placeholder for the current route's component */}
    </div>
  );
}


function App(props) {
  return (

    // If the user is logged in, render the app
    <div className="App">
      {console.log('props', props)}
      <div className="header">
        <div className="header-top">
          <div className="logo"></div>
          <img src={logo} style={{ marginRight: '4px' }} alt="Logo" />
          <h2 className="top-header">Artos</h2>
        </div>
      </div>
      <div className='app-container'>
        <div className='left-panel'>
          <nav>
            <NavLink to="/"><img src={folderOpen} alt="Logo" className="nav-logo" />Document Sets</NavLink>
            <NavLink to="/templates"><img src={archive} alt="Logo" className="nav-logo" />Templates</NavLink>
            <NavLink to="/automations"><img src={flag} alt="Logo" className="nav-logo" />Automations</NavLink>
            <NavLink to="/settings"><img src={cog} alt="Logo" className="nav-logo" />Settings</NavLink>
            <NavLink to="/help"><img src={support} alt="Logo" className="nav-logo" />Help</NavLink>
          </nav>
        </div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="about" element={<About />} />
            <Route path="help" element={<Help />} />
            <Route path="automations" element={<AutomationsPage/>} />
            <Route path="templates" element={<TemplatesPage />} />
            <Route path="settings" element={<Settings />} />
            <Route path="documentSet/:id" element={<DocumentSetsPage />} />
            <Route path="document/:id" element={<DocumentPage />} />
            <Route path="editTemplate/:id" element={<EditTemplatePage />} />
            <Route path="createTemplate" element={<CreateTemplatePage />} />
            <Route path="individualAutomationPage" element={<IndividualAutomationPage />} />
            <Route path="createDocument" element={<CreateDocumentPage />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default (App);

