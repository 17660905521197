import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import PDFWebViewComponent from '../components/PDFWebViewComponent';
import { uploadMultipleFilesUpdated, generateOutline,generateBoth, createINDModule2Section, extractAndStoreMultiple, createOutputVectorstore} from '../utils/utils';
import { getOneDocumentSet, listAllFiles, delay } from '../utils/utils';
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
// import { useAuthInfo } from '@propelauth/react';
import dots from '../icons/DotsVector.svg'

function DocumentSetsPage() {
    const { id } = useParams();
    const [step, setStep] = useState(0);
    const documentTypeList = ['Nonclinical Overview', 'Development Safety Update Report (DSUR)', 'Clinical Study Report (CSR)', 'FDA Response', 'Protocol', 'Summary of Product Characteristics (SmPC)', 'Patient Narrative', 'Stability Summary']
    const [documentType, setDocumentType] = useState('');
    
    const [documentName, setDocumentName] = useState('');
    const [productName, setProductName] = useState('');



    const [outline, setOutline] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [multipleFileList, setMultipleFileList] = useState([]);
    const [multipleFileNameList, setMultipleFileNameList] = useState([]);
    const [multipleFileContentList, setMultipleFileContentList] = useState([]);

    const [inputFiles, setInputFiles] = useState([]);

    const [viewFileUrl, setViewFileUrl] = useState('')

    const [allDocuments, setAllDocuments] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [existingMultipleFileNameList, setExistingMultipleFileNameList] = useState([]);


    const [showNote, setShowNote] = useState(false);

    // const authInfo = useAuthInfo();

    // async function getOrgAccessToken(orgId) {
    //     const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(orgId);
    //     // console.log('orgAccessToken', orgAccessToken)
    //     return orgAccessToken
    // }


    const uploadedFilesList = {
        'Nonclinical Overview': [{
            "name": "IB V.1.docx",
            "url": "https://otsukateststorage.blob.core.windows.net/input/IB V.1.docx"
        },
        {
            "name": "Graphs.xlsx",
            "url": "https://otsukateststorage.blob.core.windows.net/input/Graphs.xlsx"
        }],
        'Development Safety Update Report (DSUR)': [
            // {
            //     "name": "AdverseEventData.pdf",
            //     "url": "https://otsukateststorage.blob.core.windows.net/input/14.3.1.2A-t-ae-event-db-trt.pdf"
            // },
            // {
            //     "name": "SeriousAdverseEventData.pdf",
            //     "url": "https://otsukateststorage.blob.core.windows.net/input/14.3.2.1A-t-ae-ser-db-trt-final.pdf"
            // },
            {
                "name": "AdverseEventData.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/STE-AE-initial.xlsx"
            },
            {
                "name": "CGASdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/CGASdata.xlsx"
            },
            {
                "name": "CGISdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/CGISdata.xlsx"
            },
            {
                "name": "demographicsData.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/demographicdata.xlsx"
            },
            {
                "name": "PANSSdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/PANSSdata.xlsx"
            },
            {
                "name": "IB V.1.docx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/IB V.1.docx"
            },
            {
                "name": "Graphs.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Graphs.xlsx"
            }
            
        ],
        "Clinical Study Report (CSR)": [
            {
                "name": "AdverseEventData.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/STE-AE-initial.xlsx"
            },
            {
                "name": "CGASdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/CGASdata.xlsx"
            },
            {
                "name": "CGISdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/CGISdata.xlsx"
            },
            {
                "name": "demographicsData.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/demographicdata.xlsx"
            },
            {
                "name": "PANSSdata.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/PANSSdata.xlsx"
            },
            {
                "name": "IB V.1.docx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/IB V.1.docx"
            },
            {
                "name": "Graphs.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Graphs.xlsx"
            },
            {
                "name": "Protocol.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Prot_000.pdf"
            },
            {
                "name": "SAP.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/SAP_001.pdf"
            },
            {
                "name": "References.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/OTSUKA references.pdf"
            },
            {
                "name": "Clin Lab Data.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/clinlabdata.xlsx"
            }

        ],
        'FDA Response': [
            {
                "name": "Rexulti-PI.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Rexulti-PI.pdf"
            },
            {
                "name": "Communication.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Admin Correspondence.pdf"
            },
            
        ],
        'Protocol': [
            {
                "name": "Protocol Synopsis.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Protocol Synopsis.pdf"
            }
        ],
        'Summary of Product Characteristics (SmPC)': [
            {
                "name": "Rexulti-PI.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Rexulti-PI.pdf"

            }
        ],
        'FDA Cover Letter': [
            {
                "name": "Rexulti-PI.pdf",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Rexulti-PI.pdf"
            }
        ],
        'Patient Narrative': [
            {
                "name": "Medical Record Text.docx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Medical Record Text.docx"
            },
            {
                "name": "Demographic and Medical History.docx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/Demographic Info and Med History.docx"
            }
        ],
        'Stability Summary': [
            {
                "name": "Stability Protocol.xlsx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/stabilityprotocol.xlsx"
            },
            {
                "name": "Stability Data.docx",
                "url": "https://otsukateststorage.blob.core.windows.net/input/3.2.S.7.3 Stability Data.docx"
            }
        ]
    }

    const outputFilesIdList = {
        'Nonclinical Overview': "12a1b1d4-9a95-4575-8d92-9eeacc7558d6", 
        'Development Safety Update Report (DSUR)': "e01eef77-03b1-48ba-b149-01c9336c9330",
        'Clinical Study Report (CSR)': "ca0bffc5-5144-41a4-85b6-b656ddf870eb",
        'FDA Response': "f87840c7-be40-4458-a4d5-724486d3c167",
        'Protocol' : "ab8a59fd-bd78-4b6b-9632-ede5c3829c64",
        'Summary of Product Characteristics (SmPC)': "0c9995b1-7573-4b79-a7ee-b2cb7bed4655",
        'FDA Cover Letter': "1f051784-065a-4baf-b72f-4476cbac23ed",
        'Patient Narrative': "d32aa512-ad65-42bf-a020-39ef5bf90d13",
        'Stability Summary': "78cd98a6-f510-455c-9e3f-8f7ac6208a69",
        "Stability Data": "32dd5f7d-c0de-4be1-ae1f-ec454fc86b5a"

    }

    useEffect(() => {
        async function retrieveData() {
            setLoading(true); // Start loading
            
            // const orgAccessToken = await getOrgAccessToken(process.env.REACT_APP_ORG_ID)
            try {
                const result = await getOneDocumentSet(setLoading, setError, id, ""); // Assuming getDocumentSets returns the data
                console.log('result', result)
                setAllDocuments(result); // Update state with fetched data
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                setLoading(false); // End loading regardless of outcome
            }
        }

        async function retrieveInputs() {
            setLoading(true); // Start loading
            try {
                // const result = await listAllFiles('input'); // Assuming getDocumentSets returns the data
                const result = uploadedFilesList[documentType]
                console.log('inputs', result)
                setInputFiles(result); // Update state with fetched data
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                setLoading(false); // End loading regardless of outcome
            }
        }
        retrieveData();
        // retrieveInputs();
    }, []);


    useEffect(() => {
        function retrieveInputs() {
            setLoading(true); // Start loading
            try {
                // const result = await listAllFiles('input'); // Assuming getDocumentSets returns the data
                const result = uploadedFilesList[documentType]
                console.log('inputs', result)
                setInputFiles(result); // Update state with fetched data
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                setLoading(false); // End loading regardless of outcome
            }
        }
        retrieveInputs();
    }, [documentType]);




    const navigate = useNavigate();

    const navigateToDocumentWithUrl = (params) => {
        navigate(`/document/${params.id}`);
      };



    // const url = "https://biohaventeststorage.blob.core.windows.net/output/BHV%202100%20NCO%20Test%202%206-30.docx?sp=r&st=2024-06-30T23:07:34Z&se=2024-07-01T07:07:34Z&spr=https&sv=2022-11-02&sr=b&sig=GOnFNWnExYqZ4mbWRvwQKgvQfFXc9tZNIzrkBjdl2OE%3D"

    const resetAllFiles = () => {
        setMultipleFileList([]);
        setMultipleFileNameList([]);
        setMultipleFileContentList([]);
    };




    const handleContinue = (increment = 1) => {
        setStep((prevStep) => prevStep + increment);
    };
    
    const handleReturn = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const [newDocumentModal, setNewDocumentModal] = useState(false)

    

    const handleNewDocumentModalOpen = () => setNewDocumentModal(true)
    const handleNewDocumentModalClose = () => setNewDocumentModal(false)

    const handleClose = () => { 
        handleNewDocumentModalClose();
        resetAllFiles();
        setDocumentType('');
        setDocumentName('');
        setProductName('');
        setOutline('');
        setStep(0);


    }


    const handleSelectFile = (index, fileName, isRemove) => {
        if (isRemove) {
          // Remove the file from selectedFiles and existingMultipleFileNameList
          setSelectedFiles(selectedFiles.filter(i => i !== index));
          setExistingMultipleFileNameList(existingMultipleFileNameList.filter(name => name !== fileName));
        } else {
          // Add the file to selectedFiles and existingMultipleFileNameList
          setSelectedFiles([...selectedFiles, index]);
          setExistingMultipleFileNameList([...existingMultipleFileNameList, fileName]);
        }
      };

    const handleMultipleFiles = (files, fileContents) => {
        try {
          // Handle saving the file content or any other logic as needed for each file
          const newFiles = [];
          console.log('files here', files)
    
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const content = fileContents[i];
            newFiles.push({ name: file.name, content });
            console.log('newFiles', newFiles)
          }
    
          // Update React state with the array of file objects
          setMultipleFileList(newFiles);
          setMultipleFileNameList(newFiles.map((file) => file.name));
          setMultipleFileContentList(fileContents);
        } catch (e) {
          console.log('Error in handling files', e);
        }
      };



    // Inside your target component
    const location = useLocation();
    const rowDetails = location.state;

    // console.log(rowDetails); // This will log the row object passed as state

    const removeFile = (index) => {
        const newFileList = [...multipleFileList];
        newFileList.splice(index, 1);
        setMultipleFileList(newFileList); // Assuming your state setter is named like this
      };

    return (
        <div className='full-width-container' style={{backgroundColor:'#F9FAFB'}}>
            <div className='inner-container' style={{margin:10}}>
                <div className="main-panel-header">
                    <h2 className="top-main-panel">Document Set: {id}</h2>
                </div>
                <div className='secondary-header-container'>
                    <p className='secondary-text'>Manage and view all your documents here</p>
                </div>
                <div className='main-panel-content'>
                    <div className='folder-box' style={{marginTop:'5px', marginBottom:'5px', border:'none', backgroundColor:'white'}}>
                            <div className='folder-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                <h4>Documents</h4>
                                <div className='add-document-button' onClick={() => handleNewDocumentModalOpen()}>
                                    <img src={plus}/>
                                    <p>Add Document</p> 
                                </div>
                            </div>
                            {/* <div className='folder-info'>
                                <p style={{textAlign:'left'}}>Manage your documents here</p> 
                            </div> */}
                            {!loading && error && <div>No documents found</div>}
                            {loading ? (
                                <div>Loading...</div>
                                ) : allDocuments.length >= 1 ? (
                                allDocuments.map((document) => (
                                    <div key={document.id} style={{cursor:'pointer', border:'2px solid #D1D5DB', borderRadius:'5px'}}>
                                        <div className='document-card' onClick={() => navigateToDocumentWithUrl({ id: document.id }, document.url)} style={{ border: 'none', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '20px 10px 10px 10px' }}>
                                   <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={dots} alt="Dots" style={{ marginRight: '10px', verticalAlign: 'middle' }} />
                                        <p style={{ textAlign: 'left', margin: 0 }}>{document.doc_name}</p>
                                    </div>
                                        <p style={{ textAlign: 'left', color: '#A0A0A0' }}>Created on {document.created}</p>
                                    </div>
                               
                                    </div>
                                ))
                                ) : (
                                   <div>Loading...</div>
                                )}
                        </div>
                    </div>
                    
                </div>
                
            <Modal
                open={newDocumentModal} onClose={handleNewDocumentModalClose}>
                <div className='modal-background'>
                <Slide direction="up" in={step === 0} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h2>Select Document Type</h2>
                        {/* <div className='button-list'>
                            <button className='document-type-button' onClick={() => setDocumentType('Nonclinical Overview')}>Nonclinical Overview</button>
                        </div> */}
                        <div className='button-list'>
                        {documentTypeList.map((type) => (
                            <button
                            key={type}
                            className={`document-type-button ${documentType === type ? 'selected' : ''}`}
                            onClick={() => setDocumentType(type)}
                          >
                            {type}
                          </button>
                        ))}
                        </div>
                        {documentType && 
                        <div className='modal-button-container' style={{marginBottom:'5px'}}>
                            <button onClick={() => handleContinue()} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                        </div>}
                    </div>
                </Slide>
                {/* <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h2>Enter Document Info</h2>
                        <div className="form-container">
                            <div className="input-wrapper">
                                <label htmlFor="documentName">Document Name:</label>
                                <input
                                type="text"
                                id="documentName"
                                value={documentName}
                                onChange={(e) => setDocumentName(e.target.value)}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="productName">Product Name:</label>
                                <input
                                type="text"
                                id="productName"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="outline">Outline:</label>
                                <textarea
                                id="outline"
                                value={outline}
                                onChange={(e) => setOutline(e.target.value)}
                                rows="10"> 
                                </textarea>
                            </div>
                        </div>
                        <div className='modal-button-container'>
                        <button onClick={() => handleReturn()} className='generate-modal-submit grey-button'><p className='text-black'>Return </p></button>
                            <button onClick={() => handleContinue()} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                        </div>
                    </div>
                </Slide> */}
                {/* <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h2>Upload Documents</h2>
                            <div className="form-container">
                            <div className={`input-container ${multipleFileList.length < 1 ? 'dashed-border' : ''}`}>
                                {multipleFileList.length >= 1 && <p style={{textAlign:'left'}}>Files Selected:</p>}
                                {multipleFileList.length == 0 && <MultipleFileUploader handleFiles={handleMultipleFiles} />}
                                {multipleFileList.length >= 1 && (
                                    <>
                                    {multipleFileList.map((file, index) => (
                                        <div className='multiple-file-upload-card' key={file.name}>
                                        <div className='file-info'>
                                        <img src={paperclip} alt="Paperclip"/>
                                        <p className='file-name'>{file.name}</p>
                                        </div>
                                        <button className='remove-file-btn' onClick={() => removeFile(index)}>Remove</button>
                                    </div>
                                    ))}
                                    </>
                                )}
                            </div>
                        </div>
                        <h2>OR</h2>
                        <div className='form-container'>
                        <button className='general-select-btn' style={{ width: '20vw' }} onClick={() => handleContinue()}>
                            <p className='text-black'>Choose From Library</p></button>
                        </div>
                        <div className='modal-button-container'>
                            <button className='generate-modal-submit grey-button' onClick={() => handleReturn()}>
                                <p className='text-black'>Return</p></button>
                            {multipleFileList && <button className='generate-modal-submit' onClick={ async () => 
                                {
                                    handleContinue(3);
                                    await uploadMultipleFilesUpdated(setLoading, multipleFileList, multipleFileContentList, setError, 'input');
                                    await extractAndStoreMultiple(setLoading, setError, multipleFileNameList,  'input');
                                    await generateOutline(setLoading, setError, multipleFileNameList, outline, documentName, productName, documentType);
                                    await generateBoth(setLoading, setError, documentName, productName, documentType);
                                    // const url = await createINDModule2Section(setLoading, setError, multipleFileNameList, documentName, productName, 'input');
                                    // navigateToDocumentWithUrl({ id: id }, url);
                                    const doc_id = await createINDModule2Section(setLoading, setError, multipleFileNameList, documentName, productName, 'input');
                                    // await createOutputVectorstore(doc_id, 'output')
                                    // navigateToDocumentWithUrl({ id: doc_id });
                                }} >Generate</button>}
                        </div>
                    </div>
                </Slide> */}
                <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                    <div className='modal-content' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        {!loading && <div>
                            <h2>Choose From Library</h2>
                            <div className="files-container" style={{maxHeight:'40vh',overflow:'auto'}}>
                                {inputFiles && inputFiles.map((file, index) => (
                                    <div key={index} className={`multiple-file-upload-card ${selectedFiles.includes(index) ? 'selected-file-card' : ''}`} >
                                        <div className="file-info">
                                        <p className='file-name'>{file.name}</p>
                                        </div>
                                        <div className="file-actions">
                                        <button className='select-file-btn' style={{marginRight:'2px'}} onClick={() => {
                                            setViewFileUrl(file.url);
                                            handleContinue();
                                        }}>View</button>
                                        
                                        </div>
                                    </div>
                                    ))}
                                
                            </div> 
                            <div className='modal-button-container centered'>
                                <button className='generate-modal-submit grey-button' onClick={( ) => setShowNote(true) }>
                                        <p color='black'>Upload</p>
                                </button>
                            </div>
                            <div className='modal-button-container centered'>
                                <button className='generate-modal-submit' onClick={async () => {
                                    setLoading(true)
                                    await delay(5000);
                                    navigateToDocumentWithUrl({ id: outputFilesIdList[documentType] });
                                }
                                }>
                                        <p>Generate</p>
                                </button>
                            </div>
                            <div style={{ visibility: showNote ? 'visible' : 'hidden' }}>
                                <p style={{textAlign:'center'}}>Note: Uploading your own files is disabled in this demo environment</p>
                            </div>
                        </div>}
                        {loading && <div>Loading...</div>}
                    </div>
                </Slide>
                <Slide direction="up" in={step === 2} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <button className='generate-modal-submit' style={{margin:'10px'}}onClick={() => handleReturn()}>Back</button>
                        <div className='webviewContainer' style={viewFileUrl ? {height: '60vh', width: '75%', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                            <PDFWebViewComponent initialFile={viewFileUrl} id={id}></PDFWebViewComponent>
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={step === 3} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h2 style={{textAlign:'center'}}>Your document is generating. You will recieve a notification when your document is ready.</h2>
                        <button className='generate-modal-submit grey-button' onClick={() => handleClose()}>
                                    <p>Close</p>
                            </button>
                    </div>
                </Slide>
                </div>
            </Modal>
        </div>
    );

}

export default DocumentSetsPage;