



function delayExecution(callback, delay) {
    setTimeout(callback, delay);
  }
  
export const delay = ms => new Promise(res => setTimeout(res, ms));




const apiBaseUrl = process.env.REACT_APP_API_URL_BASE;
  
  
  
//   const msalConfig = {
//       auth: {
//           clientId: '70f0ff96-0505-4270-92df-76babba0f3d7'
//       }
//   };
  
//   const msalInstance = new PublicClientApplication(msalConfig);
  
  
//   var request = {
//     scopes: ["api://10db4c25-3f20-4719-b421-bb23cce19cd7/.default"]
//   };
  
//   let tokenResponse;
//   let objectId
  
//   const signInUser = async () => {
//     await msalInstance.initialize();
//     console.log('signing in',msalInstance)
//     try {
//       tokenResponse = await msalInstance.acquireTokenSilent(request);
//       if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
//         throw new Error('Invalid access token');
//       }
//       objectId = tokenResponse.account.idTokenClaims.oid;
//       console.log('User objectId:', objectId);
//     } catch (error) {
//       console.log('error not in interaction',error)
//       if (error) {
//         console.error('error in sign in', error)
//         // fallback to interaction when silent call fails
//         tokenResponse = await msalInstance.acquireTokenPopup(request);
//         if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
//           throw new Error('Invalid access token');
//         }
//         objectId = tokenResponse.account.idTokenClaims.oid;
//         console.log('User objectId: 2', objectId);
//       }
//       // handle other errors
//     }
//   };

  export const uploadMultipleFilesUpdated = async (setLoading, files, filesContent, setError, container) => {
  
    setLoading(true);
  
  
    console.log('filesContent', filesContent)
  
    try {
      // Use Promise.all to upload multiple files concurrently
      const uploadPromises = files.map(async (file,i) => {
        console.log('file here', filesContent[i])
        const formData = new FormData();
        formData.append('file_name', file.name);
        formData.append('file_content', new Blob([filesContent[i]]));
  
        var headers = new Headers();
        // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
        headers.append('container', container)
        headers.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");
  
  
        // headers.append('X-MS-CLIENT-PRINCIPAL-ID', '050e6292-662f-4b0f-9a89-4b0beca8bed6')
  
        const requestOptions = {
          method: 'POST',
          body: formData,
          headers: headers,
        };
  
        const response = await fetch(`${apiBaseUrl}/api/uploadFileUpdated`, requestOptions);
  
        if (!response.ok) {
          throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
        }
  
        const result = await response.text();
        
        console.log('file name:', result)
      });
  
      await Promise.all(uploadPromises);
      // await delay(2000);
  
      setLoading(false);
  
      // Handle the response from the Azure Function if needed
    } catch (error) {
      console.error('Error uploading files:', error);
      setError(true);
      setLoading(false);
    }
  };



export const generateOutline = async (setLoading, setError, files, outline, document_name, product_name,  document_type) => {

  setLoading(true);
  console.log('files', files)
  console.log('outline', outline)

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "input_path": files,
    "input_container": "input",
    "outline": outline,
    "product_name": product_name,
    "output_doc_type": document_type,
    "output_doc_name": document_name,
    "example": ""
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  await fetch(`${apiBaseUrl}/api/generateOutline`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => {
      setError(true)
      console.error(error)});


}


export const generateBoth = async (setLoading, setError, document_name, product_name, document_type) => {
  
    setLoading(true);
    console.log('document_name', document_name)
    console.log('product_name', product_name)
    console.log('document_type', document_type)

  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      "document_name": document_name,
      "product_name": product_name,
      "document_type": document_type,
    });

    
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    await fetch(`${apiBaseUrl}/api/generateBoth`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => {console.error(error)
        setError(true)
      });
}

export const createINDModule2Section = async (setLoading, setError, files, document_name, product_name ) => {

  setLoading(true);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "input_path": files,
    "conditions": "",
    "external_sources": [
      ""
    ],
    "product_name": product_name,
    "input_container": "intermediate",
    "randomID": "testnamespace7",
    "pc_index_name": "biohaven",
    "source": "",
    "query": "",
    "doc_name": document_name,
    "output_doc_name": document_name
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/createINDModule2Section`, requestOptions);
    const result = await response.text();
    setLoading(false);
    return result; // Return the result here
  } catch (error) {
    console.error(error);
    setError(true);
    setLoading(false);
    throw error; // Rethrow the error after logging and setting error state
  }

}

export const getDocumentSets = async (setLoading, setError) => {

  setLoading(true);

  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/getAllDocumentSets`, requestOptions);
    const result = await response.json();
    setLoading(false);
    return result; // Return the result here
  } catch (error) {
    console.error(error);
    setError(true);
    setLoading(false);
    throw error; // Rethrow the error after logging and setting error state
  }
}

export const getOneDocumentSet = async (setLoading, setError, id, orgAccessToken) => {

  setLoading(true);

  console.log('orgAccessToken in utils', orgAccessToken)

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + orgAccessToken.accessToken);

  console.log('id in getOneDocumentSet', id)

  const raw = JSON.stringify({
    "document_set": id
  });

  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/getOneDocumentSet`, requestOptions);
    const result = await response.json();
    setLoading(false);
    return result; // Return the result here
  } catch (error) {
    console.error(error);
    setError(true);
    setLoading(false);
    throw error; // Rethrow the error after logging and setting error state
  }
}


export const getOneDocument = async (setLoading, setError, id) => {

  setLoading(true);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  console.log('id in getOneDocument', id)

  const raw = JSON.stringify({
    "document_id": id
  });

  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/getOneDocument`, requestOptions);
    const result = await response.json();
    setLoading(false);
    return result; // Return the result here
  } catch (error) {
    console.error(error);
    setError(true);
    setLoading(false);
    throw error; // Rethrow the error after logging and setting error state
  }
}


export const saveFile = async ( id, fileContent) => {

  // Create a new URL object
  console.log('id',id)
  // // Split the filename on '.docx', take the first part, and add '.docx' back to it
  // fileName = fileName.split('.docx')[0] + '.docx';

  // console.log('newFilename',fileName); // Outputs: 'b66ec74293ad4f1587a8110a6d006317-device-protocol-template-output.docx'

  console.log('filesContent', fileContent)

  try {
    // Use Promise.all to upload multiple files concurrently
    
      // console.log('file here', fileName)
      const formData = new FormData();
      formData.append('id', id);
      formData.append('file_content', fileContent);

      var headers = new Headers();
      // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
      // headers.append("X-MS-CLIENT-PRINCIPAL-ID", '050e6292-662f-4b0f-9a89-4b0beca8bed6')

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: headers,
      };

      const response = await fetch(`${apiBaseUrl}/api/saveFile`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error uploading file: ${response.statusText}`);
      }

      const result = await response.text();
      console.log('file name:', result)

    


    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading files:', error);

  }
};


export const extractAndStoreMultiple = async(setLoading, setError, files, inputContainer) => {


  setLoading(true)

  console.log('extracting and storing')

  var myHeaders = new Headers();
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6" );
  // myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken); 

  var raw = JSON.stringify({
    "input_path": files,
    // "pc_index_name": indexName,
    "input_container": inputContainer,
    "doc_type":"testing",
    // "randomID": namespace
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`${apiBaseUrl}/api/extractAndStoreMultiple`, requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log(result)
      setLoading(false)
    }
    )
    .catch(error => {console.log('error', error)
    setError(true)
    setLoading(false)});
    

}


export const getSources = async (setLoading, setError, document_id,section_text) => {

  setLoading(true)

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "document_id": document_id,
    "section_text": section_text
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/getSources`, requestOptions);
    const result = await response.json(); // Await the parsing of the JSON
    console.log(result);
    return result; // Return the parsed JSON
  } catch (error) {
    console.error(error);
    setError(error); // Assuming setError is used to handle errors
    return null; // Return null or an appropriate value indicating failure
  }

  }


  export const findInconsistencies = async (document_id, section) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      "document_id": document_id,
      "section": section,
      "input_container": "output"
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${apiBaseUrl}/api/findInconsistencies`, requestOptions);
      const result = await response.json(); // Await the parsing of the JSON
      console.log(result);
      return result; // Return the parsed JSON
    } catch (error) {
      console.error(error);
      return null; // Return null or an appropriate value indicating failure
    }
  }


  export const chatWithDoc = async (question, document_id) => {
   
    // console.log('chatting with', protocolFile)
    // const url_part = protocolFile.replace("https://", "")
    // console.log('url_part', url_part)
    // const fileName = protocolFile.split("/").pop()
    // console.log('fileNam in chatwithbot', fileName)
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
  
  
    var raw = JSON.stringify({
      // "input_path": protocolFile,
      "document_id": document_id,
      "question": question
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    return fetch(`${apiBaseUrl}/api/chatwithInputDoc`, requestOptions)
      .then(response => response.text())
      // .then(result => console.log(result))
      .catch(error => console.log('error', error));
    // await delay(2000);
  
  
  
    }


export const createOutputVectorstore = async (document_id, container_name) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  console.log('creating output vector store!')

  const raw = JSON.stringify({
    "document_id": document_id,
    "input_container": container_name,
    "doc_type": "testing"
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  await fetch(`${apiBaseUrl}/api/createOutputVectorstore`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));

  }


// export const handleMultipleFiles = (files, fileContents, setMultipleFileList, setMultipleFileNameList, setMultipleFileContentList) => {
//     try {
//       // Handle saving the file content or any other logic as needed for each file
//       const newFiles = [];
//       console.log('files here', files)

//       for (let i = 0; i < files.length; i++) {
//         const file = files[i];
//         const content = fileContents[i];
//         newFiles.push({ name: file.name, content });
//         console.log('newFiles', newFiles)
//       }

//       // Update React state with the array of file objects
//       setMultipleFileList(newFiles);
//       setMultipleFileNameList(newFiles.map((file) => file.name));
//       setMultipleFileContentList(fileContents);
//     } catch (e) {
//       console.log('Error in handling files', e);
//     }
//   };



export const regenerateSection = async ( document_id, sections, additional_info, files) => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "document_id": document_id,
    "sections": sections,
    "additional_info": additional_info,
    "input_path": files
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  await fetch(`${apiBaseUrl}/api/regenerateSection`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));


}


export const createNewDocumentSet = async ( documentSet) => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "document_set_name": documentSet
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/createNewDocumentSet`, requestOptions);
    const result = await response.text();
    console.log(result);  

    return result; // Return the result here
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error after logging and setting error state
  }
}

export const listAllFiles = async (container_name) => {

  const raw = JSON.stringify({
    "container_name": container_name
  });

  const requestOptions = {
    method: "POST",
    redirect: "follow",
    body: raw
  };
  
  try {
    const response = await fetch(`${apiBaseUrl}/api/listAllBlobs`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json(); // Assuming the server returns JSON. Use .text() if it's plain text
    return result; // This will be the value of all blobs
  } catch (error) {
    console.error(error);
    throw error; // Rethrow to allow caller to handle
  }

}


export const handleMultipleFiles = (files, fileContents) => {
  try {
    const newFiles = [];
    const fileNameList = [];
    const fileContentList = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const content = fileContents[i];
      newFiles.push({ name: file.name, content });
      fileNameList.push(file.name);
      fileContentList.push(content);
    }

    // Return an object containing the three lists
    return {
      multipleFileList: newFiles,
      multipleFileNameList: fileNameList,
      multipleFileContentList: fileContentList,
    };
  } catch (e) {
    console.log('Error in handling files', e);
    // Return null or an empty object to indicate failure
    return null;
  }
};

export const processTemplate = async (setLoading, setError, templateName, documentType) => {
  setLoading(true);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "template_name": templateName,
    "document_type": documentType
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(`${apiBaseUrl}/api/processTemplate`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.text(); // Assuming the server returns JSON. Use .text() if it's plain text
    return result; // This will be the value of all blobs
  } catch (error) {
    console.error(error);
    throw error; // Rethrow to allow caller to handle
  }


}


export const emailTrigger = async () => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "email": ""
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  await fetch(`${apiBaseUrl}/api/emailTrigger`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));

}




