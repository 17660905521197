import React from 'react';
import SidebarEditComponent from './SidebarEditComponent';
import SidebarPen from '../icons/SidebarPen.png';
import SidebarLines from '../icons/SidebarLines.png';
import SidebarSend from '../icons/SidebarSend.png';
import MainButton from './MainButton';

const SidebarComponent = ({ title, items, onClick }) => {
    const handleClick = async () => {
        console.log('saved')
    }

    return (
        <div className="sidebar">
            <h2 style={{ textAlign: 'left', marginBottom: '10px', paddingTop: '10px', paddingLeft: '10px' }}>{title}</h2>
            <hr />
            {items.map((item, index) => (
                <SidebarEditComponent onClick={onClick(item.text)} key={index} iconSrc={item.iconSrc} text={item.text} subText={item.subtext} />
            ))}
            <div style={{ marginBottom: '20px' }}>

            </div>
            <MainButton onClick={handleClick} text='Add' width="95%" icon="fas fa-plus" className='sidebar-button' />
        </div>
    );
};

export default SidebarComponent;