import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import '../App.css'; // Import your CSS file

const CustomStatusButton = ({ isOn }) => {

    return (
        <button
            className={`status-button ${isOn === "Ready for review" ? 'on' : isOn === "Draft generating..." ? 'off' : 'maybe'}`}        >
            {isOn === "Ready for review" ? 'Ready for review' : isOn === "Draft generating..." ? 'Draft generating...' : 'Draft not started'}
        </button>
    );
};

export default CustomStatusButton;