import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import PDFWebViewComponent from '../components/PDFWebViewComponent';
import { uploadMultipleFilesUpdated, generateOutline, generateBoth, createINDModule2Section, extractAndStoreMultiple, createOutputVectorstore, processTemplate, delay, emailTrigger } from '../utils/utils';
import { getOneDocumentSet, listAllFiles } from '../utils/utils';
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import dots from '../icons/DotsVector.svg'
import CustomTableButton from '../components/CustomTableButton';
import MainButton
 from '../components/MainButton';
function AutomationsPage() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)

    const [step, setStep] = useState(1)

    const navigate = useNavigate();

    const navigateToDocumentWithUrl = (params) => {
        navigate(`/individualAutomationPage`);
    };

    const handleClick = async () => {

        setLoading(true)
        setModalOpen(true)
        await delay(5000);
        await emailTrigger();
        setLoading(false)

    }

    const handleRowClick = (params) => {
        console.log('params', params.row)
        navigate(`/individualAutomationPage`);
      };

      const colDefs = [
        { field: 'name', headerName: 'Name', flex: 4, headerClassName: 'table-header'},
        { field: 'created', headerName: 'Created', flex: 1 },
        { field: 'updated', headerName: 'Last Updated', flex: 1 },
        { 
            field: 'status', 
            headerName: 'Status', 
            flex: 1,
            renderCell: (params) => <CustomTableButton isOn={params.row.status} />
        },
    ];

    const templatesList = [
        {
            type: 'CSR AE table automation',
            name: 'CSR AE table automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/NCO Template.docx',
            id: "12a1b1d4-9a95-4575-8d92-9eeacc7558d6",
            status: true,
            created: "2024-08-01",
            updated: "2024-08-01"
        },
        {
            type: 'DSUR data update automation',
            name: 'DSUR data update automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/DSUR Template.docx',
            id: "e01eef77-03b1-48ba-b149-01c9336c9330",
            status: true,
            created: "2024-08-01",
            updated: "2024-08-01"
        },
        {
            type: 'IB non-clinical data update automation',
            name: 'IB non-clinical data update automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/Artos - Otsuka CSR template.docx',
            id: "ca0bffc5-5144-41a4-85b6-b656ddf870eb",
            status: false,
            created: "2024-08-01",
            updated: "2024-08-02"
        },
    ]


    return (
        <div className='full-width-container' style={{ backgroundColor: '#F9FAFB' }}>
            <div className='inner-container'>
                <div className='header-container'>
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Automations</h2>
                    </div>
                    <div className='header-bar'>
                        <div className='secondary-header-container'>
                            <p className='secondary-text'>Create and manage your automations here </p>
                        </div>
                        <MainButton onClick={navigateToDocumentWithUrl} text='Create' width="15%" icon="fas fa-plus" className="main-button" />
                    </div>
                    <div className='main-panel-content'>
                    <div className='template-datagrid' width="100%">
                            <DataGrid rows={templatesList} columns={colDefs} onRowClick={handleRowClick} autoHeight/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className='modal-background'>
                    <Slide direction='up' in={step === 1} mountOnEnter unmountOnExit >
                        <div>
                            <div className='modal-content'>

                                {loading ? <div>Saving Automation...</div> :
                                    <>
                                        <div>Your automation has saved and updated a file</div>
                                        <button className='generate-modal-submit' style={{ padding: '0', marginLeft: '0', marginTop: '20px', width: '200px' }} onClick={() => navigateToDocumentWithUrl()}>
                                            View Updated File
                                        </button>
                                    </>


                                }

                            </div>
                        </div>
                    </Slide>
                    <Slide direction='up' in={step === 2} mountOnEnter unmountOnExit >
                        <div className='modal-content'>
                            <div className='webviewContainer' style={{ height: '60vh', width: '75%', visibility: 'visible' }}>
                                <PDFWebViewComponent initialFile={'https://otsukateststorage.blob.core.windows.net/output/Artos - Otsuka CSR modified.docx'} id={'123'} startPageNumber={51}></PDFWebViewComponent>
                            </div>
                        </div>
                    </Slide>
                </div>
            </Modal>
        </div>
    )

}

export default AutomationsPage;