import React, { useState, useRef } from 'react';

const UploadArea = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        if (files.length > 0) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div
            className="upload-area"
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {selectedFiles.length === 0 && <p>Click to select files, or drag and drop</p>}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
            />
            {selectedFiles.length > 0 && (
                <div className="upload-file-info">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="upload-file-item">
                            <p style={{ marginLeft: 8 }}>{file.name}</p>
                            <button onClick={() => handleRemoveFile(index)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <i className="fas fa-trash-alt" style={{ marginLeft: '10px'}}></i>
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default UploadArea;