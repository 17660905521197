import React, { useState } from 'react';
import MainButton from './MainButton'; // Adjust the import path as necessary
import '../App.css'; // Import your CSS file

const CreateDocumentTextInput = ({ onNextClick, categories }) => {
    const [fileName, setFileName] = useState('');
    const [additionalText, setAdditionalText] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
    };

    const handleAdditionalTextChange = (event) => {
        setAdditionalText(event.target.value);
    };

    const handleSaveClick = () => {
        // Handle save logic here
        console.log('File name saved:', fileName);
        console.log('Additional text saved:', additionalText);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    console.log('CreateTemplatePage - onNextClick:', onNextClick);


    return (
        <div className="upload-container">
            <div className="input-container">
                <p className='secondary-text'>Name:</p>
                <input
                    type="text"
                    value={fileName}
                    onChange={handleFileNameChange}
                    placeholder="Enter a name for your file"
                    className="file-input"
                />
                <p className='secondary-text'>Description:</p>
                <input
                    type="text"
                    value={additionalText}
                    onChange={handleAdditionalTextChange}
                    placeholder="Optional"
                    className="file-input"
                />
                <p className='secondary-text'>Category:</p> 
                <select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="file-input"
                >
                    <option value="" disabled>Select a category</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category.value}>{category.label}</option>
                    ))}
                </select>
            </div>

            <div className="button-container">
                <MainButton
                    onClick={onNextClick}
                    text="Next"
                    width="100px"
                    backgroundColor="#1f477d"
                    fontColor="#ffffff"
                    className="main-button"
                />
            </div>
        </div>
    );
};

export default CreateDocumentTextInput;