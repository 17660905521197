import '../App.css'; // Import your CSS file
import React from 'react';
import { useNavigate } from 'react-router-dom';

const MainButton = ({ onClick, to, text, width, icon, className, backgroundColor, fontColor, border }) => {
    const navigate = useNavigate();
    console.log('MainButton - onClick:', onClick);
    return (
        <button
            onClick={onClick}
            className={`${className}`}
            style={{ 
                width: width, 
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 4,
                backgroundColor: backgroundColor, 
                color: fontColor, 
                border: border
            }}
        >
            {text}
            <i className={icon} style={{ marginLeft: 8 }}></i>
        </button>
    );
};

export default MainButton;