import {useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback} from 'react';
import WebViewer from '@pdftron/webviewer'
import { saveFile } from '../utils/utils';
import save from '../icons/Save.svg';

const WebViewComponent = forwardRef(({ hasLink, initialFile, fileKey, id, onTextSelected }, ref) => {
  const viewer = useRef(null);

  const [selectedText, setSelectedText] = useState('');
  const [pageNumber, setPageNumber] = useState(null);

  const [instance, setInstance] = useState(null);
  const [isActionButtonAdded, setIsActionButtonAdded] = useState(false); // Step 1: New state variable 
  var buttonNeeded = true;


  // Make the method accessible from the parent component
  useImperativeHandle(ref, () => ({
    getText: () => {
      return { selectedText, pageNumber };
    }
  }));

  useEffect(() => {
    if (viewer.current && initialFile) {
      console.log('initialFile', initialFile)
    WebViewer(
      {
        path: '/public',
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        initialDoc: initialFile,
        enableOfficeEditing: true,
      },
      viewer.current,
      
    ).then((i) => {
      setInstance(i);
      const { documentViewer, Annotations, annotationManager, Tools } = i.Core;

      


      documentViewer.addEventListener('documentLoaded', () => {
        console.log('doc Loaded HERE')



        const textSelectedListener = (quads, text, page) => {
          if (text.length > 0) {
            setSelectedText(text);
            setPageNumber(page);
            // console.log('page number', text, page);
          }
        };
    
        documentViewer.addEventListener('textSelected', textSelectedListener);

      
  

        return () => {
          documentViewer.removeEventListener('textSelected', textSelectedListener);
        };
      });
    });

    
  }}, [initialFile]);

  useEffect(() => {
    if (instance) {
      setIsActionButtonAdded(true);
      console.log('Loading new document:', initialFile);
      // var doc = await instance.docViewer.getDocument().getFileData({flags: instance.Core.SaveOptions.LINEARIZED});
      // instance.UI.loadDocument(initialFile, {extension: 'docx', enableOfficeEditing: true})
    }
  }, [initialFile, instance, fileKey])

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
});

export default WebViewComponent;