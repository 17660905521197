import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import WebViewComponent from '../components/WebViewComponent';
import { getOneDocument, uploadMultipleFilesUpdated } from '../utils/utils';
import SectionComponent from '../components/SectionComponent';
import InsightsComponent from '../components/InsightsComponent';
import ConflictsComponent from '../components/ConflictsComponent';
import MainButton from '../components/MainButton';

import '../App.css'; // Import your CSS file
import { createOutputVectorstore } from '../utils/utils';



function EditTemplatePage() {



    const { id } = useParams();
    const location = useLocation();

    const objectUrl = location.state?.url;

    console.log('Object URL from state:', objectUrl);
    // const { url } = location.state;

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [documentUrl, setDocumentUrl] = useState("")
    const [fileUrl, setFileUrl] = useState("")
    const [sectionInfo, setSectionInfo] = useState([])

    const [activeTab, setActiveTab] = useState('Chat');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };




    useEffect(() => {



        async function retrieveData() {
            // setLoading(true); // Start loading
            try {

                const result = await getOneDocument(setLoading, setError, id); // Assuming getDocumentSets returns the data
                console.log('result', result)
                setDocumentUrl(objectUrl)
                // setDocumentUrl(result['url']); // Update state with fetched data
                setSectionInfo(result['section_info'])
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                setLoading(false); // End loading regardless of outcome
            }
        }
        retrieveData();


    }, [])

    const webViewRef = useRef(null);

    const handleButtonClick = () => {
        const { selectedText, pageNumber } = webViewRef.current.getText();
        console.log(`Selected text: ${selectedText}, Page number: ${pageNumber}`);
        return selectedText;
        // Now you can use selectedText and pageNumber as needed
    };

    const optionsArray = [
        { value: 'option1', label: 'Non-Clinical Overview' },
        { value: 'option2', label: 'DSUR' },
        { value: 'option3', label: 'CSR' }
    ];

    const optionElements = optionsArray.map(option => (
        <option key={option.value} value={option.value}>
            {option.label}
        </option>
    ));



    return (
        <div className='full-width-container'>
            <div className='inner-container' style={{ margin: 0 }}>
                {/* <h1>Document Page</h1> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflow: 'auto' }}>

                    <div className='webviewContainer' style={documentUrl ? { flex: 2, height: '100vh', width: '75%', visibility: 'visible', paddingLeft: '20px' } : { height: '0', width: '0', visibility: 'hidden' }}>
                        <WebViewComponent ref={webViewRef} initialFile={documentUrl} id={id} onTextSelected={(selectedText, pageNumber) => {
                            console.log(`Text selected: ${selectedText} on page ${pageNumber}`);
                        }}></WebViewComponent>
                    </div>
                    <div className='right-panel' style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <div className='template-tab-content'>
                            <p className='secondary-text'>AI System: </p>
                            <select className='dropdown-list' style={{ width: '100%', marginBottom: '20px' }}>
                                {optionElements}
                            </select>
                            <p className='secondary-text' style={{ marginTop: -2 }}>Artos builds an "AI System" for each document type to maximize quality. Make sure to select the right AI System for the template being uploaded.</p>
                            <p className='secondary-text'>Options:</p>
                            <MainButton onClick={handleButtonClick} text='Save' width="100%" icon="fa-solid fa-arrow-right-arrow-left" className="main-button" />
                            <MainButton onClick={handleButtonClick} text='Replace' width="100%" icon="fa-solid fa-arrow-right-arrow-left" className="unfilled-button" backgroundColor="#FEF3C7" fontColor="#B45309"/>
                            <MainButton onClick={handleButtonClick} text='Delete' width="100%" icon="fa-solid fa-arrow-right-arrow-left" className="unfilled-button" />
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={error}>
                <div className='modal-background'>
                    <div className='modal-content'>
                        <h2>Error</h2>
                        <p>There was an error loading the document. This document may still be generating.</p>
                        <button onClick={() => setError(false)}>Close</button>
                    </div>
                </div>
            </Modal>
        </div>

    )


}

export default EditTemplatePage;