import React from 'react';
import MainButton from './MainButton'; // Adjust the import path as necessary
import '../App.css'; // Import your CSS file

const ComplexLoaderComponent = ({ onNextClick1, onNextClick2 }) => {
    return (
        <div className="upload-container" style={{ height: '50%'}}>
            <div className="loader-container">
                <div className="loader"></div>
                <p style={{width: '400px'}}>Drafting document, this may take a while... </p>
                <p style={{width: '400px'}}>Feel free to wait, otherwise Artos will send an email when your document is ready. </p>

                <div className="button-container">
                    <MainButton
                        onClick={onNextClick1}
                        text=" Return to Home"
                        width="100%"
                        backgroundColor="#1f477d"
                        fontColor="#ffffff"
                        className="unfilled-button"
                        border="2px solid #1f477d"
                    />
                </div>
            </div>
        </div>
    );
};

export default ComplexLoaderComponent;