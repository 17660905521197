import React from 'react';
import SidebarEditComponent from './SidebarEditComponent';
import SidebarPen from '../icons/SidebarPen.png';
import SidebarLines from '../icons/SidebarLines.png';
import SidebarSend from '../icons/SidebarSend.png';
import MainButton from './MainButton';

const UpdateDocSidebar = ({ title, items }) => {
    const handleClick = async () => {
        console.log('saved')
    }

    return (
        <div className="sidebar">
            <h2 style={{ textAlign: 'left', marginBottom: '10px', paddingTop: '10px', paddingLeft: '10px' }}>Hello</h2>
            <hr />
        </div>
    );
};

export default UpdateDocSidebar;