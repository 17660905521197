import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import PDFWebViewComponent from '../components/PDFWebViewComponent';
import { uploadMultipleFilesUpdated, generateOutline, generateBoth, createINDModule2Section, extractAndStoreMultiple, createOutputVectorstore, processTemplate } from '../utils/utils';
import { getOneDocumentSet, listAllFiles } from '../utils/utils';
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import dots from '../icons/DotsVector.svg'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import CustomTableButton from '../components/CustomTableButton';
import MainButton from '../components/MainButton';

function TemplatesPage() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [templateFiles, setTemplateFiles] = useState([]);
    const [newTemplateName, setNewTemplateName] = useState('')
    const [newTemplateModal, setNewTemplateModal] = useState(false)
    const [viewTemplateModal, setViewTemplateModal] = useState(false)
    const [fileUrl, setFileUrl] = useState('')

    const [step, setStep] = useState(0)

    const documentTypeList = ['Nonclinical Overview', 'Document Type 1', 'Document Type 2', 'Document Type 3', 'Document Type 4', 'Document Type 5']
    const [documentType, setDocumentType] = useState('')


    const [multipleFileList, setMultipleFileList] = useState([]);
    const [multipleFileNameList, setMultipleFileNameList] = useState([]);
    const [multipleFileContentList, setMultipleFileContentList] = useState([]);

    const removeFile = (index) => {
        const newFileList = [...multipleFileList];
        newFileList.splice(index, 1);
        setMultipleFileList(newFileList); // Assuming your state setter is named like this
    };


    const handleContinue = () => {
        setStep((prevStep) => prevStep + 1);
    };



    // const templatesList = [
    // const [templatesList, setTemplatesList] = useState([
    const templatesList = [
        {
            type: 'Nonclinical Overview',
            name: 'NCO Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/NCO Template.docx',
            id: "12a1b1d4-9a95-4575-8d92-9eeacc7558d6",
            created: "2024-08-03",

        },
        {
            type: 'Development Safety Update Report (DSUR)',
            name: 'DSUR Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/DSUR Template.docx',
            id: "e01eef77-03b1-48ba-b149-01c9336c9330",
            created: "2024-08-05",
        },
        {
            type: 'Clinical Study Report (CSR)',
            name: 'Artos - Otsuka CSR template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/Artos - Otsuka CSR template.docx',
            id: "ca0bffc5-5144-41a4-85b6-b656ddf870eb",
            created: "2024-08-01",
        },
        {
            type: 'FDA Reponse Template',
            name: 'FDA Response Letter Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/FDA Response Letter Template.docx',
            id: "f87840c7-be40-4458-a4d5-724486d3c167",
            created: "2024-08-12",
        },
        {
            type: 'Protocol Template',
            name: 'Protocol Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/Protocol Template.docx',
            id: "ab8a59fd-bd78-4b6b-9632-ede5c3829c64",
            created: "2024-08-12",
        },
        {
            type: 'Cover Letter Template',
            name: 'FDA Cover Letter Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/FDA cover letter template.docx',
            id: "1f051784-065a-4baf-b72f-4476cbac23ed",
            created: "2024-08-13",
        },
        {
            type: 'SmPC Template',
            name: 'SmPC Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/REXULTI SmPC template.docx',
            id: "0c9995b1-7573-4b79-a7ee-b2cb7bed4655",
            created: "2024-08-25",
        },
        {
            type: 'Stability Data Template',
            name: 'Stability Data Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/3.2.P.8.3 Stability Data-template.docx',
            id: "32dd5f7d-c0de-4be1-ae1f-ec454fc86b5a",
            created: "2024-08-27",
        },
        {
            type: 'Stability Summary Template',
            name: 'Stability Summary Template.docx',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/3.2.P.8.3 Stability Summary-template.docx',
            id: "78cd98a6-f510-455c-9e3f-8f7ac6208a69",
            created: "2024-08-27",
        }
    ]
    // );

    // const [colDefs, setColDefs] = useState([
    //     { headerName: "Name", filter: true, valueGetter: p => p.data.name.toLocaleString(), flex: 4, cellStyle: { textAlign: 'left' } },
    //     { headerName: "AI System", filter: true, valueGetter: p => p.data.type, flex: 2, cellStyle: { textAlign: 'left' } },
    //     { headerName: "Created At", filter: true, valueGetter: p => "Aug. 04, 2024".toLocaleString(), flex: 1, cellStyle: { textAlign: 'left' } },
    //     { headerName: "", valueGetter: p => "Aug. 04, 2024", flex: 1, cellRenderer: (params) => <CustomTableButton id={params.data.id} /> },
    // ]);

    const colDefs = [
        { field: 'name', headerName: 'Filename', flex: 2, headerClassName: 'table-header'},
        { field: 'type', headerName: 'AI System', flex: 2 },
        { field: 'created', headerName: 'Created', flex: 1 },
        // { field: 'id', headerName: 'Last Updated', flex: 1 },
    ];

    const defaultColDef = {
        flex: 1,
    };

    // const handleTemplateButtonClick = (url) => {
    //     setFileUrl(url)
    //     setViewTemplateModal(true)
    // }

    const navigate = useNavigate();

    const navigateToDocumentWithUrl = () => {
        navigate(`/createTemplate`);
    };

    useEffect(() => {
        async function retrieveInputs() {
            setLoading(true); // Start loading
            try {
                const result = await listAllFiles('templates'); // Assuming getDocumentSets returns the data
                console.log('inputs', result)
                setTemplateFiles(result); // Update state with fetched data
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                setLoading(false); // End loading regardless of outcome
            }
        }
        retrieveInputs();
    }, []);

    const handleMultipleFiles = (files, fileContents) => {
        try {
            // Handle saving the file content or any other logic as needed for each file
            const newFiles = [];
            console.log('files here', files)

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const content = fileContents[i];
                newFiles.push({ name: file.name, content });
                console.log('newFiles', newFiles)
            }

            // Update React state with the array of file objects
            setMultipleFileList(newFiles);
            setMultipleFileNameList(newFiles.map((file) => file.name));
            setMultipleFileContentList(fileContents);
        } catch (e) {
            console.log('Error in handling files', e);
        }
    };

    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    const formattedDate = threeDaysAgo.toLocaleDateString();

    const handleRowClick = (params) => {
        console.log('params', params.row)
        navigate(`/editTemplate/${params.row.id}`, { state: { ...params.row } });
      };

    return (
        <div className='full-width-container' >
            <div className='inner-container'>
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Templates</h2>
                    </div>
                    <div className='header-bar'>
                        <div className='secondary-header-container'>
                            <p className='secondary-text'>Create and manage your template configurations here </p>
                        </div>
                        <MainButton onClick={navigateToDocumentWithUrl} text='Create' width="15%" icon="fas fa-plus" className="main-button" />
                    </div>
                    <div className='main-panel-content'>
                        {/* {templatesList.map((template, index) => (
                            <div className='folder-box' style={{ marginTop: '5px', marginBottom: '5px', border: 'none', backgroundColor: 'white' }}>
                                <div className='folder-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={dots} alt="Dots" />
                                        <h4 style={{ margin: 10 }}>{template.type}</h4>
                                    </div>
                                </div>
                                <div key={index} style={{ cursor: 'pointer', border: '2px solid #D1D5DB', borderRadius: '5px' }} onClick={() => {
                                    setFileUrl(template.url)
                                    setViewTemplateModal(true)
                                }}>
                                    <div className='document-card' style={{ border: 'none', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={dots} alt="Dots" style={{ marginRight: '10px' }} />
                                            <p style={{ textAlign: 'left', margin: 0 }}>{template.name}</p>
                                        </div>
                                        <p style={{ textAlign: 'left', color: '#A0A0A0' }}>Created on {formattedDate}</p>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                        <div className='template-datagrid' width="100%">
                            <DataGrid rows={templatesList} columns={colDefs} onRowClick={handleRowClick} autoHeight/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={newTemplateModal} onClose={() => setNewTemplateModal(false)}>
                <div className='modal-background'>
                    <Slide direction="up" in={step === 0} mountOnEnter unmountOnExit>
                        <div className='modal-content'>
                            <h4>Select Document Type</h4>
                            <div className='button-list'>
                                {documentTypeList.map((type) => (
                                    <button
                                        key={type}
                                        className={`document-type-button ${documentType === type ? 'selected' : ''}`}
                                        onClick={() => setDocumentType(type)}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                            {documentType &&
                                <div className='modal-button-container'>
                                    <button onClick={() => handleContinue()} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                                </div>}

                        </div>
                    </Slide>
                    <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                        <div className='modal-content'>
                            <h4>Create New Template</h4>
                            <div className="form-container">
                                <div className={`input-container ${multipleFileList.length < 1 ? 'dashed-border' : ''}`}>
                                    {multipleFileList.length >= 1 && <p style={{ textAlign: 'left' }}>Files Selected:</p>}
                                    {multipleFileList.length == 0 && <MultipleFileUploader handleFiles={handleMultipleFiles} allowMultiple={false} />}
                                    {multipleFileList.length >= 1 && (
                                        <>
                                            {multipleFileList.map((file, index) => (
                                                <div className='multiple-file-upload-card' key={file.name}>
                                                    <div className='file-info'>
                                                        <img src={paperclip} alt="Paperclip" />
                                                        <p className='file-name'>{file.name}</p>
                                                    </div>
                                                    <button className='remove-file-btn' onClick={() => removeFile(index)}>Remove</button>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='modal-buttons'>
                                <button className='select-file-btn' onClick={async () => {
                                    setNewTemplateModal(false)
                                    await uploadMultipleFilesUpdated(setLoading, multipleFileList, multipleFileContentList, setError, 'templates');
                                    await processTemplate(setLoading, setError, multipleFileNameList[0], documentType)
                                }}>Upload</button>
                            </div>
                        </div>

                    </Slide>
                </div>
            </Modal>
            <Modal open={viewTemplateModal} onClose={() => setViewTemplateModal(false)}>
                <div className='modal-background'>
                    <div className='modal-content'>
                        <h4>View Template</h4>
                        <div className='webviewContainer' style={fileUrl ? { height: '60vh', width: '75%', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                            <PDFWebViewComponent initialFile={fileUrl}></PDFWebViewComponent>
                        </div>
                        <div className='modal-buttons'>
                            <button className='select-file-btn' style={{ margin: '5px' }} onClick={() => setViewTemplateModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default TemplatesPage;