import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import '../App.css'; // Import your CSS file

const CustomTableButton = ({ isOn }) => {
    return (
        <button 
            className={`toggle-button ${isOn ? 'on' : 'off'}`}
        >
            {isOn ? 'On' : 'Off'}
        </button>
    );
};

export default CustomTableButton;