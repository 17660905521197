import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { getSources } from '../utils/utils';
import Modal from '@mui/material/Modal';
import { Slide } from '@mui/material';
import { MultipleFileUploader } from './MultipleFileUploader';
import { extractAndStoreMultiple, regenerateSection, listAllFiles } from '../utils/utils';
import { Routes, Route, Link, useNavigate, Outlet, NavLink} from 'react-router-dom';
import paperclip from '../icons/Paper clip.svg'
import PDFWebViewComponent from './PDFWebViewComponent';

import '../App.css'

function SectionComponent({id, sectionInfo, onButtonClick}) {

    const [sources, setSources] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [addSourcesModal, setAddSourcesModal] = useState(false)
    const [regenerateModal, setRegenerateModal] = useState(false)
    const [step, setStep] = useState(0)
    const [additionalInfo, setAdditionalInfo] = useState('')

    const [sectionDetails, setSectionDetails] = useState([])
    const [documentViewModal, setDocumentViewModal] = useState(false)

    const [multipleFileList, setMultipleFileList] = useState([]);
    const [multipleFileNameList, setMultipleFileNameList] = useState([]);
    const [multipleFileContentList, setMultipleFileContentList] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);


    const [inputType, setInputType] = useState("");
    const [sourceType, setSourceType] = useState("");

    const handleAddSourcesModalOpen = () => setAddSourcesModal(true)
    const handleAddSourcesModalClose = () => setAddSourcesModal(false)

    const handleRegenerateModalOpen = () => setRegenerateModal(true)
    const handleRegenerateModalClose = () => setRegenerateModal(false)

    const navigate = useNavigate()


    const handleContinue = (incrementBy = 1) => {
        setStep((prevStep) => prevStep + incrementBy);
    };
    
    const handleReturn = (decrementBy = 1) => {
        setStep((prevStep) => prevStep - decrementBy);
    };

    const handleTextareaChange = (event) => {
        setAdditionalInfo(event.target.value);
    };

    const removeFile = (index) => {
        const newFileList = [...multipleFileList];
        newFileList.splice(index, 1);
        setMultipleFileList(newFileList); // Assuming your state setter is named like this
      };


    const [sourceResults, setSourceResults] = useState([])

    const [expandedSectionIndex, setExpandedSectionIndex] = useState(null); // Track the expanded section


    const [selectedFiles, setSelectedFiles] = useState([]);
    const [existingMultipleFileNameList, setExistingMultipleFileNameList] = useState([]);
    const [inputFiles, setInputFiles] = useState([]);
    const [viewFileUrl, setViewFileUrl] = useState('')

    const [selectedText, setSelectedText] = useState('');
    const [showSourceButton, setShowSourceButton] = useState(false); 
    const [sectionName, setSectionName] = useState('') 


    console.log('sectionInfo in comonent', sectionInfo)


    const onTextSelected = () => {
        const text = onButtonClick();
        setSelectedText(text);

        if (text) {
            setShowSourceButton(true);
        }

        else {
            alert('Please select some text to get sources')
        }

        return text;
       
    }


    const handleSelectFile = (index, fileName, isRemove) => {
        if (isRemove) {
          // Remove the file from selectedFiles and existingMultipleFileNameList
          setSelectedFiles(selectedFiles.filter(i => i !== index));
          setExistingMultipleFileNameList(existingMultipleFileNameList.filter(name => name !== fileName));
        } else {
          // Add the file to selectedFiles and existingMultipleFileNameList
          setSelectedFiles([...selectedFiles, index]);
          setExistingMultipleFileNameList([...existingMultipleFileNameList, fileName]);
        }
      };

    const toggleSection = (index) => {
        // Toggle the section: if it's already expanded, collapse it, otherwise expand it
        setExpandedSectionIndex(expandedSectionIndex === index ? null : index);
        setSectionName(index !== -1 ? sectionInfo[index].section : '');
    };


    const resetAllFiles = () => {
        setMultipleFileList([]);
        setMultipleFileNameList([]);
        setMultipleFileContentList([]);
    };

    const handleMultipleFiles = (files, fileContents) => {
        try {
          // Handle saving the file content or any other logic as needed for each file
          const newFiles = [];
          console.log('files here', files)
    
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const content = fileContents[i];
            newFiles.push({ name: file.name, content });
            console.log('newFiles', newFiles)
          }
    
          // Update React state with the array of file objects
          setMultipleFileList(newFiles);
          setMultipleFileNameList(newFiles.map((file) => file.name));
          setMultipleFileContentList(fileContents);
        } catch (e) {
          console.log('Error in handling files', e);
        }
      };


    const sectionText = {
        "document_name": "Document",
        "all_sources": [
            {
                "section": "1.1 Introduction",
                "sources": [
                    {
                        "source": "IB V.1.docx",
                        "page": 1
                    }
                ]
            }
        ]
    }

    useEffect(() => {

        console.log('sectionText', sectionText['all_sources'])

        setSectionDetails(sectionText['all_sources'])
    }, [])

        

    // useEffect(() => {
    //     async function retrieveData() {
    //         if (expandedSectionIndex === null) return; // Exit if no section is selected
    
    //         const currentSection = sectionInfo[expandedSectionIndex];
    //         console.log('currentSection', currentSection)
    //         if (!currentSection) return; // Exit if the current section is not found
    
    //         const { sources, questions: [firstQuestion] } = currentSection; // Destructure sources and question from the current section
    
    //         try {
    //             const result = await getSources(setLoading, setError, sources, firstQuestion);
    //             console.log('result in sectionComponent', result);
    //             setSourceResults(result); // Assuming you want to do something with the result here, like updating state

    //         } catch (error) {
    //             console.error(error);
    //             setError(true); // Set error state
    //         }
    //     }

        
    
    //     retrieveData();
        
    // }, [expandedSectionIndex, sectionInfo]);


    // UseEffect to get all files in the input folder
    useEffect(() => {

        async function retrieveInputs() {
            // setLoading(true); // Start loading
            try {
                const result = await listAllFiles('input'); // Assuming getDocumentSets returns the data
                console.log('inputs', result)
                setInputFiles(result); // Update state with fetched data
                setError(false); // Reset error state if successful
            } catch (error) {
                console.error(error);
                setError(true); // Set error state
            } finally {
                // setLoading(false); // End loading regardless of outcome
            }
        }

        retrieveInputs();


    }, []);

    const getAllSources = async (text) => {
        setLoading(true); // Start loading
        try {
            
            const result = await getSources(setLoading, setError, id, text); // Assuming getDocumentSets returns the data
            console.log('result in sectionComponent', result);
            setSourceResults(result); // Assuming you want to do something with the result here, like updating state

        } catch (error) {
            console.error(error);
            setError(true); // Set error state
        }

        setLoading(false); // End loading regardless of outcome
    }

    const [selectedItems, setSelectedItems] = useState([]);

  // Function to handle individual checkbox change
  const handleCheckboxChange = (sectionName, isChecked) => {
    if (isChecked) {
      // Add the item to the list if it's checked
      setSelectedItems(prev => [...prev, sectionName]);
    } else {
      // Remove the item from the list if it's unchecked
      setSelectedItems(prev => prev.filter(item => item !== sectionName));
    }
  };

  // Function to handle "Select All" checkbox change
  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      // Add all section names to the list
      const allSectionNames = sectionInfo.map(section => section.section);
      setSelectedItems(allSectionNames);
    } else {
      // Clear the list
      setSelectedItems([]);
    }
    // Update all checkboxes to match "Select All" state
    document.querySelectorAll('[id^="checkbox-"]').forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  };

    return(
        <div >
            {/* <h4>View Sections</h4> */}
                {/* <div style={{overflow:'auto', maxHeight:'70vh'}}>
                {sectionInfo.map((section, index) => (
                
                <div className='source-card' key={index} onClick={() => toggleSection(index)}>
                    <h3 style={{ cursor: 'pointer'}}>
                        {section.section_name}
                    </h3>
                    {expandedSectionIndex === index && (
                        <div style={{ borderLeft: "3px solid #465AC3", paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px" }} >
                            {section.sources.map((source, sourceIndex) => (
                                <p key={sourceIndex} style={{color: "#465AC3", fontSize:"12"}}>{source}</p>
                            ))}
                            {sourceResults && sourceResults.map((result, resultIndex) => (
                                <p key={`result-${resultIndex}`}>{result.text}</p>
                            ))}
                        </div>
                    )}
                </div>
                ))}
                </div> */}
                <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
                    {/* <select onChange={(e) => toggleSection(parseInt(e.target.value))} value={expandedSectionIndex}>
                        <option value={-1}>Select a section</option>
                        {sectionInfo.map((section, index) => (
                            <option key={index} value={index}>
                                {section.section_name}
                            </option>
                        ))}
                    </select>
                    {expandedSectionIndex !== null && (
                            <p>Now select some text from this section to get the sources</p>
                    )}
                    {expandedSectionIndex !== null && (
                        <button onClick={()=> onTextSelected()}>Select text
                        </button>)}
                    {showSourceButton && (
                        <button onClick={() => getAllSources()}>Get Sources</button>
                    )} */}
                     {/* <select onChange={(e) => toggleSection(parseInt(e.target.value))} value={expandedSectionIndex}>
                        <option value={-1}>Select a section</option>
                        {sectionDetails && sectionDetails.map((section, index) => (
                            <option key={index} value={index}>
                                {section.section}
                            </option>
                        ))}
                    </select> */}
                    {/* {expandedSectionIndex !== null && (
                            <p>Now select some text from this section to get the sources</p>
                    )}
                    {expandedSectionIndex !== null && (
                        <button onClick={()=> onTextSelected()}>Select text
                        </button>)}
                    {showSourceButton && (
                        <button onClick={() => getAllSources()}>Get Sources</button>
                    )} */}

                    <p>Highlight some text and click "Get Sources" to see what information was used to generate a section.</p>
                     
                    <button onClick={async ()=> 
                        {const textReturned = await onTextSelected()
                        await getAllSources(textReturned)
                        }}

                        style={{ 
                            backgroundColor: '#1F477D', 
                            color: 'white', 
                            border: 'none', 
                            padding: '10px', 
                            borderRadius: '5px', 
                            cursor: 'pointer', 
                            margin:'10px'
                        }}
                        >Get Sources</button>
                    {/* {showSourceButton && (
                        <button onClick={() => getAllSources()}>Get Sources</button>
                    )} */}

                    <div>
                        {loading ? (
                            <div style={{ textAlign: 'center', padding: '20px'}}>
                                <p>Loading...</p>
                            </div>
                        ) : (
                            sourceResults && sourceResults.map((result, resultIndex) => (
                                <div 
                                    key={`result-container-${resultIndex}`} 
                                    onClick={() => {
                                        setPageNumber(result.page_number)
                                        setViewFileUrl("https://otsukateststorage.blob.core.windows.net/input/" + result.source);
                                        setDocumentViewModal(true);
                                    }}
                                    style={{ 
                                        marginBottom: '20px', 
                                        padding: '10px', 
                                        border: '1px solid #E5E7EB', 
                                        borderRadius: '5px', 
                                        textAlign: 'left', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        borderLeft: '3px solid #1F477D' 
                                    }}
                                >
                                    <p 
                                        key={`result-source-${resultIndex}`} 
                                        style={{ 
                                            color: '#1F477D', 
                                            fontSize: '18px', 
                                            margin: '0 0 5px 0' 
                                        }}
                                    >
                                        {result.source}
                                    </p>
                                    <p 
                                        key={`result-section-${resultIndex}`} 
                                        style={{ 
                                            color: '#6B7280', 
                                            fontSize: '14px', 
                                            margin: '0 0 5px 0' 
                                        }}
                                    >
                                        {result.section ? `${result.section}:` : ''}
                                    </p>
                                    <p 
                                        key={`result-${resultIndex}`} 
                                        style={{ 
                                            margin: '0' 
                                        }}
                                    >
                                        {result.text}
                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                
                    {/* {sectionInfo.map((section, index) => (
                        <div className='source-card' key={index}>
                            {expandedSectionIndex === index && (
                                <div style={{ borderLeft: "3px solid #465AC3", paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    {section.sources.map((source, sourceIndex) => (
                                        <p key={sourceIndex} style={{ color: "#465AC3", fontSize: "12px" }}>{source}</p>
                                    ))}
                                    {sourceResults && sourceResults.map((result, resultIndex) => (
                                        <p key={`result-${resultIndex}`}>{result.text}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))} */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop:'10px' }}>
                    <button onClick={() => handleAddSourcesModalOpen()}  style={{ 
                        flexGrow: 1, 
                        backgroundColor: '#1F477D', 
                        color: 'white', 
                        border: 'none', 
                        padding: '10px', 
                        borderRadius: '5px', 
                        cursor: 'pointer' 
                    }}>Add Sources</button>
                </div>

                <Modal
                open={addSourcesModal} onClose={handleAddSourcesModalClose}>
                <div className='modal-background'>
                <Slide direction="up" in={step === 0} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                    <h2 style={{textAlign:'center'}}> Adding sources is only supported in the full version of the Artos platform. With this tool, you can easily regenerate sections with new source data. </h2>
                    {/* <div style={{ overflowY: 'scroll', height: '200px' }}>
                        {console.log('sectiondetails', sectionDetails)}
                        {sectionInfo.map((section, index) => (
                            <div key={index}>
                            <input
                                type="checkbox"
                                id={`checkbox-${index}`}
                                name={section.section}
                                onChange={(e) => handleCheckboxChange(section.section, e.target.checked)}
                            />
                            <label htmlFor={`checkbox-${index}`}>{section.section}</label>
                            </div>
                        ))}
                        <div>
                            <input
                            type="checkbox"
                            id="select-all"
                            onChange={(e) => handleSelectAllChange(e.target.checked)}
                            />
                            <label htmlFor="select-all">Select All</label>
                        </div>
                        {selectedItems.length > 0 && (
                            <button onClick={() => handleContinue()}>Next</button>
                        )}
                        </div> */}
                    </div>
                </Slide>
                <Slide direction="up" in={step === 1} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                    <h2>Add Sources?</h2>
                        <div className="form-container">
                        <div className='button-list'>
                        {['Yes','No'].map((type) => (
                            <button
                            key={type}
                            className={`document-type-button ${inputType === type ? 'selected' : ''}`}
                            onClick={() => setInputType(type)}
                          >
                            {type}
                          </button>
                        ))}
                        </div>
                        {inputType && 
                        <div className='modal-button-container'>
                            <button onClick={() => handleContinue(inputType === 'Yes' ? 2 : 1)} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                        </div>}
                        <div className='modal-button-container'>
                            <button onClick={() => handleReturn(inputType === 'Yes' ? 2 : 1)} className='generate-modal-submit'><p className='text-white'>Return</p></button>
                        </div>
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={step === 2} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h3>Add additional info to modify the section:</h3>
                        <textarea
                            className="textarea-class"
                            rows="4"
                            cols="50"
                            value={additionalInfo}
                            onChange={handleTextareaChange}>
                        </textarea>
                        <button onClick={async () => {
                            // await extractAndStoreMultiple(setLoading, setError, multipleFileNameList,  'input')
                            await regenerateSection(id, selectedItems, additionalInfo, multipleFileNameList)
                            navigate(0)
                        }}>Regenerate</button>
                    
                    </div>
                </Slide>
                <Slide direction="up" in={step === 3} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                    <h2>Select Source Type</h2>
                        <div className="form-container">
                        <div className='button-list'>
                        {['New Source','From Library'].map((type) => (
                            <button
                            key={type}
                            className={`document-type-button ${sourceType === type ? 'selected' : ''}`}
                            onClick={() => setSourceType(type)}
                          >
                            {type}
                          </button>
                        ))}
                        </div>
                        {sourceType && 
                        <div className='modal-button-container'>
                            <button onClick={() => handleContinue(sourceType === 'New Source' ? 3 : 1)} className='generate-modal-submit'><p className='text-white'>Confirm</p></button>
                        </div>}
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={step === 4} mountOnEnter unmountOnExit>
                <div className='modal-content'>
                        <h2>Choose From Library</h2>
                        <div className="files-container" style={{maxHeight:'40vh',overflow:'auto'}}>
                            {inputFiles.map((file, index) => (
                                <div key={index} className={`multiple-file-upload-card ${selectedFiles.includes(index) ? 'selected-file-card' : ''}`} >
                                    <div className="file-info">
                                    <p className='file-name'>{file.name}</p>
                                    </div>
                                    <div className="file-actions">
                                    <button className='view-file-btn' style={{marginRight:'2px'}} onClick={() => {
                                        setViewFileUrl(file.url);
                                        handleContinue();
                                    }}>View</button>
                                    {selectedFiles.includes(index) ? (
                                        <button className='remove-file-btn' onClick={() => handleSelectFile(index, file.name, true)}>Remove</button>
                                    ) : (
                                        <button className='select-file-btn' onClick={() => handleSelectFile(index, file.name, false)}>Select</button>
                                    )}
                                    </div>
                                </div>
                                ))}
                            
                        </div> 
                        <div className='modal-button-container' style={{margin:'20px'}}>
                            <button className='generate-modal-submit grey-button' onClick={() => handleReturn()}>
                                    <p>Return</p>
                            </button>
                        </div>
                        <div className='modal-button-container' style={{margin:'20px'}}>
                            <button className='generate-modal-submit blue-button' onClick={() => handleContinue(7)}>
                                    <p>Continue</p>
                            </button>
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={step === 5} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <button className='generate-modal-submit' style={{margin:'10px'}} onClick={() => handleReturn()}>Back</button>
                        <div className='webviewContainer' style={viewFileUrl ? {height: '60vh', width: '75%', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                            <PDFWebViewComponent initialFile={viewFileUrl} id={id}></PDFWebViewComponent>
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={step === 6} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                    <h2>Upload Input Documents</h2>
                        <div className="form-container">
                        <div className={`input-container ${multipleFileList.length < 1 ? 'dashed-border' : ''}`}>
                            {multipleFileList.length >= 1 && <p style={{textAlign:'left'}}>Files Selected:</p>}
                            {multipleFileList.length == 0 && <MultipleFileUploader handleFiles={handleMultipleFiles} />}
                            {multipleFileList.length >= 1 && (
                                <>
                                {multipleFileList.map((file, index) => (
                                    <div className='multiple-file-upload-card' key={file.name}>
                                    <div className='file-info'>
                                      <img src={paperclip} alt="Paperclip"/>
                                      <p className='file-name'>{file.name}</p>
                                    </div>
                                    <button className='remove-file-btn' onClick={() => removeFile(index)}>Remove</button>
                                  </div>
                                ))}
                                </>
                            )}
                        </div>
                        {multipleFileList.length >= 1 && <button className='upload-blue-initial' onClick={() => handleContinue(5)}>Next</button>}
                    </div>
                    </div>
                </Slide>
                
                <Slide direction="up" in={step === 11} mountOnEnter unmountOnExit>
                    <div className='modal-content'>
                        <h3>Any other info to include?</h3>
                        <textarea
                            className="textarea-class"
                            rows="4"
                            cols="50"
                            value={additionalInfo}
                            onChange={handleTextareaChange}>
                        </textarea>
                        <button onClick={async () => {
                            await extractAndStoreMultiple(setLoading, setError, multipleFileNameList,  'input')
                            await regenerateSection(id, selectedItems, additionalInfo, multipleFileNameList)
                            navigate(0)
                        }}>Regenerate</button>
                    
                    </div>
                </Slide>
                    
                </div>
            </Modal>

            <Modal open={documentViewModal} onClose={() => setDocumentViewModal(false)}>
            <div className='modal-background'>

            <div className='modal-content'>
                        {/* <button className='generate-modal-submit' style={{margin:'10px'}}>Back</button> */}
                        <div className='webviewContainer' style={viewFileUrl ? {height: '60vh', width: '75%', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                            <PDFWebViewComponent initialFile={viewFileUrl} id={id} startPageNumber={pageNumber}></PDFWebViewComponent>
                        </div>
            </div>


            </div>

            </Modal>
        </div>

        
    )

}

export default SectionComponent;