import React from 'react';
import IconTextRectangle from './SidebarIcon';

const SidebarEditComponent = ({ iconSrc, text, subText, onClick }) => {
    return (
        <button onClick={onClick} style={{ border: '0px', backgroundColor: '#F9FAFB', width: '100%' }}>
            <div className="sidebar-element" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="icon-text-container" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={iconSrc} alt="icon" className="sidebar-icon" style={{ marginRight: '10px' }} />
                    <div className="edit-container">
                        <div style={{ fontWeight: "bold", textAlign: 'left', paddingBottom: '2px' }}>{text}</div>
                        <div style={{ textAlign: 'left', color: '#6B7280' }}>{subText}</div>
                    </div>
                </div>
                <i className="fas fa-chevron-right chevron-icon" style={{ marginLeft: 'auto' }}></i>
            </div>
        </button>
    );
};

export default SidebarEditComponent;